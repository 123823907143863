/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.tempo{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: honeydew;
  font-family: "Andale Mono", Helvetica, sans-serif;
}
.tampa{
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-row-gap: 30px;
  grid-template-rows: 1fr auto 1fr auto;
}
.tempa{
  grid-column: 2;
  grid-row: 2;
  display: flex;
  justify-content: center;
}
.train{
  grid-row: 4;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1{
  writing-mode: vertical-rl;
  font-size: 0.97rem;
  font-weight: 100;
  margin: 0;
  padding: 0;
  padding-left: 0px;
  margin-top: -0.1rem;
  line-height: 1rem;
}
